import React from 'react';
import { FlatList } from 'react-native';
import photos from '../../config/mock-data';
import PhotoFullWithModal from '../PhotoFullWithModal';
// Using useWindowDimensions from 'react-native' crashes web
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface PhotoCarouselProps {
  index: number;
}

function PhotoCarousel({ index: initialIndex }: PhotoCarouselProps) {
  const dimensions = useWindowDimensions();
  const { width } = dimensions;
  const keyExtractor = item => item.source.uri;
  return (
    <FlatList
      initialScrollIndex={initialIndex}
      horizontal
      data={photos}
      snapToAlignment="start"
      snapToInterval={width}
      decelerationRate="fast"
      getItemLayout={(data, index) => ({
        length: width,
        offset: width * index,
        index,
      })}
      pagingEnabled
      keyExtractor={keyExtractor}
      renderItem={({ item }) => <PhotoFullWithModal item={item} />}
      windowSize={5}
      maxToRenderPerBatch={1}
    />
  );
}

export default PhotoCarousel;
