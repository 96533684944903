import React, { PureComponent } from 'react';
import { ActivityIndicator, ScaledSize, ImageResizeMode } from 'react-native';
import {
  StyledPhotoFullImage,
  StyledActivityIndicatorWrapper,
  StyledPhotoFullWrapper,
} from './PhotoFull.styled';
import { theme } from '../../config/theme';

interface Props {
  source: {
    uri: string;
  };
  dimensions: ScaledSize;
  resizeMode: ImageResizeMode;
  onPress: Function;
  onLongPress: Function;
}

interface State {
  loaded: boolean;
}

class PhotoFull extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  onLoad = () => {
    this.setState({
      loaded: true,
    });
  };

  render() {
    const { dimensions, source, resizeMode, onPress, onLongPress } = this.props;
    const { loaded } = this.state;
    return (
      <StyledPhotoFullWrapper onPress={onPress} onLongPress={onLongPress}>
        <StyledPhotoFullImage
          source={source}
          dimensions={dimensions}
          onLoad={this.onLoad}
          resizeMode={resizeMode}
          style={{
            borderRightWidth: 1,
            borderRightColor: theme.backgroundColor,
            borderLeftWidth: 1,
            borderLeftColor: theme.backgroundColor,
          }}
        >
          {!loaded && (
            <StyledActivityIndicatorWrapper dimensions={dimensions}>
              <ActivityIndicator size="large" color="#ffffff" />
            </StyledActivityIndicatorWrapper>
          )}
        </StyledPhotoFullImage>
      </StyledPhotoFullWrapper>
    );
  }
}

export default PhotoFull;
