import React, { useState } from 'react';
import { ImageResizeMode, View, /* Text, */ StyleSheet } from 'react-native';
// import { MaterialIcons } from '@expo/vector-icons';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { breakpoints } from '../../config/theme';
// import Modal from '../Modal';
import PhotoFull from '../PhotoFull';

function PhotoFullWithModal({ item }: { item: { fullSizeUri: string } }) {
  const [modalVisible, setModalVisible] = useState(false);
  const dimensions = useWindowDimensions();
  const { width, height } = dimensions;
  const isMobile = width < breakpoints.small;
  const defaultResizeMode = isMobile ? 'cover' : 'contain';
  const [resizeMode, setResizeMode] = useState<ImageResizeMode>(
    defaultResizeMode,
  );
  const onPress = () => {
    setModalVisible(!modalVisible);
  };
  const onLongPress = () => {
    const newResizeMode = resizeMode === 'cover' ? 'contain' : 'cover';
    setResizeMode(newResizeMode);
  };
  const { fullSizeUri } = item;
  const source = {
    uri: fullSizeUri,
  };
  const styles = StyleSheet.create({
    viewWrapper: {
      flex: 1,
    },
    view: {
      flex: 1,
      marginTop: Math.ceil((height / 3) * 2),
      backgroundColor: 'white',
      marginBottom: 3,
      borderRadius: 20,
    },
    closeIcon: {
      color: 'black',
      alignSelf: 'flex-end',
      marginTop: 10,
      marginRight: 10,
    },
    text: {
      color: 'black',
      alignSelf: 'flex-start',
    },
  });
  return (
    <View key={fullSizeUri} style={styles.viewWrapper}>
      {/* <Modal
        hasBackdrop
        backdropOpacity={0.4}
        onBackdropPress={onPress}
        hardwareAccelerated
        onSwipeComplete={onPress}
        isVisible={modalVisible}
        swipeDirection="down"
        useNativeDriver
      >
        <View style={styles.view}>
          <MaterialIcons
            name="close"
            style={styles.closeIcon}
            size={32}
            onPress={onPress}
          />
          <Text style={styles.text}>Hide Modal</Text>
        </View>
      </Modal> */}
      <PhotoFull
        key={fullSizeUri}
        source={source}
        dimensions={dimensions}
        resizeMode={resizeMode}
        onPress={onPress}
        onLongPress={onLongPress}
      />
    </View>
  );
}

export default PhotoFullWithModal;
