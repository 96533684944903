import React, { ReactNode } from 'react';
import { StyledMinimalLayoutView } from './MinimalLayout.styled';

interface Props {
  children: ReactNode;
}

function MinimalLayout({ children }: Props) {
  return <StyledMinimalLayoutView>{children}</StyledMinimalLayoutView>;
}

export default MinimalLayout;
