import React, { ReactNode } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import { RootStackParamList } from '../common';

export const { Navigator, Screen } = createStackNavigator<RootStackParamList>();

interface Props {
  children: ReactNode;
}

function SlideFromRight({ children }: Props) {
  return (
    <NavigationContainer>
      <Navigator
        headerMode="none"
        screenOptions={{
          ...TransitionPresets.SlideFromRightIOS,
        }}
      >
        {children}
      </Navigator>
    </NavigationContainer>
  );
}

export default SlideFromRight;
