// Using https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints values
export const breakpoints = {
  xsmall: 576,
  small: 768,
  medium: 992,
  large: 1200,
  xlarge: 1500,
};

export const theme = {
  textColor: '#ffffff',
  backgroundColor: '#161616',
};
