import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import MinimalLayout from '../../layouts/MinimalLayout';
import { RootStackParamList } from '../../navigators/common';
import PhotoCarousel from '../../components/PhotoCarousel';
import photos from '../../config/mock-data';

type PhotoScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'photo'
>;

interface PhotoScreenProps {
  navigation: PhotoScreenNavigationProp;
  route: {
    params: {
      source: {
        uri: string;
      };
    };
  };
}

function PhotoScreen({ route }: PhotoScreenProps) {
  const {
    params: {
      source: { uri },
    },
  } = route;
  const index = photos.findIndex(photo => photo.thumbnail === uri);
  return (
    <MinimalLayout>
      <PhotoCarousel index={index} />
    </MinimalLayout>
  );
}

export default PhotoScreen;
