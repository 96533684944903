import React, { ReactElement } from 'react';
import { StyledSafeAreaView } from './SafeView.styled';

interface Props {
  children: ReactElement;
  forceInset: any;
}

function SafeView({ children, forceInset }: Props) {
  return (
    <StyledSafeAreaView forceInset={forceInset}>{children}</StyledSafeAreaView>
  );
}

export default SafeView;
