import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { StyledHeader, Logo } from './Header.styled';

interface Props {
  onScrollTop: Function;
}

function Header({ onScrollTop }: Props) {
  const handleOnPress = () => {
    onScrollTop();
  };
  return (
    <StyledHeader>
      <TouchableWithoutFeedback onPress={handleOnPress}>
        <Logo source={require(`../../../assets/gablabelle001.png`)} />
      </TouchableWithoutFeedback>
    </StyledHeader>
  );
}

export default Header;
