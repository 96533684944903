import React, { createRef } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { FlatList } from 'react-native';
import DefaultLayout from '../../layouts/DefaultLayout';
import { RootStackParamList } from '../../navigators/common';
import PhotoMasonryList from '../../components/PhotoMasonryList';

type HomeScreenNavigationProp = StackNavigationProp<RootStackParamList, 'home'>;

interface HomeScreenProps {
  navigation: HomeScreenNavigationProp;
}

export default function HomeScreen({ navigation }: HomeScreenProps) {
  const ref = createRef<FlatList<any>>();
  const onScrollTop: Function = () => {
    ref.current.scrollToIndex({ index: 0, animated: true });
  };
  const onPhotoSelect = (payload: { source: { uri: string } }) => {
    navigation.navigate('photo', payload);
  };
  return (
    <DefaultLayout onScrollTop={onScrollTop}>
      <PhotoMasonryList ref={ref} onPhotoSelect={onPhotoSelect} />
    </DefaultLayout>
  );
}
