import React, { ReactNode } from 'react';
import SafeView from '../../components/SafeView';
import { StyledDefaultLayoutView } from './DefaultLayout.styled';
import Header from '../../components/Header';

interface Props {
  children: ReactNode;
  onScrollTop: Function;
}

function DefaultLayout({ children, onScrollTop }: Props) {
  return (
    <SafeView forceInset={{ bottom: 'never' }}>
      <StyledDefaultLayoutView>
        <Header onScrollTop={onScrollTop} />
        {children}
      </StyledDefaultLayoutView>
    </SafeView>
  );
}

export default DefaultLayout;
