// import { Header as HeaderElement } from '@expo/html-elements';
import styled from 'styled-components/native';
import { View, Image } from 'react-native';

export const StyledHeader = styled(View)`
  padding: 10px;
`;

export const Logo = styled(Image)`
  width: 60px;
  height: 36px;
  opacity: 0.3;
`;
