// const photos = [
//   'mariage-editorial-a-lauberge-saint-gabriel-couple-2',
//   'mariage-editorial-a-lauberge-saint-gabriel-gene',
//   'mariage-editorial-a-lauberge-saint-gabriel-hugues',
//   'mariage-editorial-a-lauberge-saint-gabriel-couple',
//   'mariage-editorial-a-lauberge-saint-gabriel-gene-au-micro',
//   'gene-h-3d',
//   'gene-h',
//   'gene-h-2',
//   'eve-et-son-cafe',
//   'eve-et-le-journal',
//   'eve-d',
//   'mystique-v-4',
//   'mystique-v-2',
//   'mystique-v',
//   'gabrielle-chez-dulcedo',
//   'gabrielle-chez-dulcedo-2',
//   'hellcath-3',
//   'hellcath-4',
//   'hellcath',
//   'hellcath-5',
//   'virginie',
//   'virginie-5',
//   'virginie-4',
//   'jacqueline-s-3',
//   'jacqueline-s-2',
//   'lamyae',
//   'lamyae-chez-montage',
//   'samantha-k-5',
//   'samantha-k-3',
//   'amanda',
//   'karine-et-les-feuilles',
//   'jean-david-p',
//   'samantha-k-4',
//   'heloise-a-la-fenetre',
//   'sophia-m-2',
//   'sophia-m',
//   'mystique-v-3',
//   'samantha-k-2',
//   'samantha-k',
//   'virginie-2',
//   'virginie-3',
//   'cynthia-d',
//   'laurence-b',
//   'oro-3',
//   'oro',
//   'oro-2',
//   'mystique-v-7',
//   'melissa-v-3',
//   'melissa-v-4',
//   'red-gene',
//   'blue-gene',
//   'amelie-songe',
//   'mystique-v-5',
//   'mystique-v-6',
//   'melissa-v-2',
//   'melissa-v',
//   'laurence-b-smile',
//   'sortilege',
//   'nadia',
//   'julie-b',
//   'amelie-peanut-tv',
//   'annie-anton-au-couche-de-soleil',
//   'annie-anton-2',
//   'annie-anton',
//   'annie-anton-4',
//   'marie-helene-christian-3',
//   'marie-helene-christian',
//   'marie-helene-christian-2',
//   'steph-r-3',
//   'steph-r-2',
//   'steph-r',
//   'laurence-b-oeil',
//   'veronique-c',
//   'emy',
//   'josie',
//   'yasmine-l',
//   'valene-yasmine',
//   'barbara',
//   'eve-boudoir',
//   'eve-c',
//   'melissa-b',
//   'heloise',
//   'nadia-g',
//   'stephanie-v',
//   'sandra-k',
//   'marie-helene-et-christian',
//   'montreal',
//   'plage-a-ixtapa',
//   'sandra-et-son-livre',
//   'paskal-vesselinov',
//   'green-lateez',
//   'sandra-pour-caracol-2',
//   'amanda-portrait',
//   'richelieu',
//   'heloise-n',
//   'genevieve-pour-caracol',
// ];

const uriPrefix = 'https://www.gablabelle.com';

const photos = [
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5496-Edit-Edit-Edit-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5496-Edit-Edit-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5496-Edit-Edit-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5197-Edit-Edit-corrected-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5197-Edit-Edit-corrected.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5197-Edit-Edit-corrected-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5368-Edit-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5368-Edit-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5368-Edit-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5307-Final_1200px-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5307-Final_1200px.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5307-Final_1200px-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5459-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5459-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_wedding_mariage_20130513-_DSF5459-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_fashion2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_fashion2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_fashion2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_fashion-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_fashion.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_fashion-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc_cafe-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc_cafe.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc_cafe-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc_journal-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc_journal.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc_journal-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v4-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v4.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v4-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v7-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v7.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v7-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130718-DSCF9833-Edit-570x570.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130718-DSCF9833-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130718-DSCF9833-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130718-DSCF9723-Edit-mixed-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130718-DSCF9723-Edit-mixed.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130718-DSCF9723-Edit-mixed-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8162-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8162-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8162-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8180-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8180-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8180-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8108-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8108-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8108-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8229-Edit-2-Edit-2-570x570.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8229-Edit-2-Edit-2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130623-_DSF8229-Edit-2-Edit-2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130509-_DSF4975-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130509-_DSF4975-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130509-_DSF4975-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie4-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie4.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie4-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6773-Edit-Edit-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6773-Edit-Edit-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6773-Edit-Edit-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6812-Edit-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6812-Edit-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6812-Edit-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_lamyae-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_lamyae.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_lamyae-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_lamyae_mode-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_lamyae_mode.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_lamyae_mode-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130509-_DSF4802-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130509-_DSF4802-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130509-_DSF4802-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_black_and_white-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_black_and_white.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_black_and_white-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_sexy-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_sexy.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_sexy-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_karine_ringuet-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_karine_ringuet.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_karine_ringuet-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_jean-david-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_jean-david.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_jean-david-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130621-_DSF7705-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130621-_DSF7705-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130621-_DSF7705-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130621-_DSF8051-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130621-_DSF8051-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130621-_DSF8051-Edit-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v6-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v6.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v6-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_cute-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_cute.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_cute-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_lingerie-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_lingerie.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_samantha_knox_lingerie-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_virginie3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6460-Edit-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6460-Edit.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle-20130607-_DSF6460-Edit-thumbnail.jpg`,
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_boudoir_laurence3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_boudoir_laurence3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_boudoir_laurence3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_oro3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_rouge-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_rouge.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode_rouge-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_gene_houle_mode-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_amelie_badier-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_amelie_badier.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_amelie_badier-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v8-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v8.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v8-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_marina_mystive_v3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v4-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v4.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_melissa_v4-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_laurence-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_laurence.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_laurence-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_laurence_grondin-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_laurence_grondin.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_laurence_grondin-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nadia-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nadia.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nadia-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_julie_bessette-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_julie_bessette.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_julie_bessette-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_amelie_badier2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_amelie_badier2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_amelie_badier2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa4-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa4.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa4-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa5-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa5.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa5-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa6-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa6.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa6-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal4-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal4.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal4-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_steph_ray2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_laurence2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_laurence2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_laurence2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_veronique_cimon-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_veronique_cimon.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_veronique_cimon-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_emy-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_emy.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_emy-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_josie_jones-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_josie_jones.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_josie_jones-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_yasmine_boudoir-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_yasmine_boudoir.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_yasmine_boudoir-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_valene_yasmine-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_valene_yasmine.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_valene_yasmine-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sexy3-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sexy3.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sexy3-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_boudoir_photographer_eve_dubuc-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_eve_chagnon-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_eve_chagnon.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_eve_chagnon-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_melissa-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_melissa.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_melissa-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_heloise-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_heloise.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_heloise-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nadia2-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nadia2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nadia2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_stephanie_verreault-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_stephanie_verreault.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_stephanie_verreault-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sandra-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sandra.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sandra-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_vieux-montreal-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_landscape_paysage_photographer_montreal_nuit_night-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_landscape_paysage_photographer_montreal_nuit_night.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_landscape_paysage_photographer_montreal_nuit_night-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa2-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa2.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_mariage_wedding_photographer_ixtapa2-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sandra_livre-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sandra_livre.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_sandra_livre-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_dj_paskal_vesselinov-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_dj_paskal_vesselinov.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_dj_paskal_vesselinov-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nightlife_green_and_lateez-570x570.jpg`,
      dimensions: { height: 570, width: 570 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nightlife_green_and_lateez.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_nightlife_green_and_lateez-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 240 },
  },
  {
    source: {
      uri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_sandra-270x270.jpg`,
      dimensions: { height: 270, width: 270 },
    },
    fullSizeUri: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_sandra.jpg`,
    thumbnail: `${uriPrefix}/assets/photos/gablabelle_montreal_photographe_portrait_photographer_bijoux_caracol_sandra-thumbnail.jpg`,
    thumbnailDimensions: { width: 360, height: 540 },
  },
];

export default photos;
