import React, { forwardRef, useState } from 'react';
import { Platform } from 'react-native';
import MasonryList from 'react-native-masonry-list';
import allPhotos from '../../config/mock-data';
import { breakpoints, theme } from '../../config/theme';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface Props {
  onPhotoSelect: Function;
}

function getMasonryLayoutOptions(width: number) {
  if (width >= breakpoints.xlarge)
    return { columns: 7, gutter: 10, initialItems: 30 };
  if (width >= breakpoints.large)
    return { columns: 6, gutter: 10, initialItems: 25 };
  if (width >= breakpoints.medium)
    return { columns: 5, gutter: 10, initialItems: 20 };
  if (width >= breakpoints.small)
    return { columns: 4, gutter: 10, initialItems: 20 };
  return { columns: 2, gutter: 6, initialItems: 10 };
}

function PhotoMasonryList({ onPhotoSelect }: Props, ref) {
  const { width } = useWindowDimensions();
  const { columns, gutter, initialItems } = getMasonryLayoutOptions(width);
  const colWidth = (width - columns * gutter - gutter) / columns;
  // Fake pagination with infinite scroll
  const photos = [...allPhotos];
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(photos.length / initialItems);
  const images = photos
    .filter((p, i) => {
      const itemsToDisplay = initialItems * page;
      return i < itemsToDisplay;
    })
    .map(({ thumbnail, thumbnailDimensions }) => ({
      source: {
        uri: thumbnail,
        dimensions: thumbnailDimensions,
      },
    }));
  const onEndReached = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  return (
    <MasonryList
      images={images}
      onPressImage={onPhotoSelect}
      columns={columns}
      containerWidth={width}
      backgroundColor={theme.backgroundColor}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.4}
      listContainerStyle={{
        justifyContent: 'center',
        marginTop: -(gutter / 2),
        ...(Platform.OS === 'web' ? { flex: 1 } : { minHeight: '100%' }),
      }}
      imageContainerStyle={{
        backgroundColor: 'transparent',
        borderRadius: gutter,
        margin: gutter / 2,
        width: colWidth,
      }}
      masonryFlatListColProps={{
        removeClippedSubviews: false,
        ref,
      }}
    />
  );
}

export default forwardRef(PhotoMasonryList);
