import '@expo/match-media';
import React from 'react';
import { StatusBar } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ThemeProvider } from 'styled-components/native';
import SlideFromRight, { Screen } from './src/navigators/SlideFromRight';
import HomeScreen from './src/screens/HomeScreen';
import PhotoScreen from './src/screens/PhotoScreen';
import { theme } from './src/config/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SafeAreaProvider>
        <StatusBar barStyle="light-content" />
        <SlideFromRight>
          <Screen
            name="home"
            component={HomeScreen}
            options={{ title: 'Your feed' }}
          />
          <Screen
            name="photo"
            component={PhotoScreen}
            options={{ title: 'Your photo' }}
          />
        </SlideFromRight>
      </SafeAreaProvider>
    </ThemeProvider>
  );
}

export default App;
