import styled from 'styled-components/native';
import {
  ImageBackground,
  ScaledSize,
  TouchableWithoutFeedback,
} from 'react-native';

export const StyledPhotoFullWrapper = styled(TouchableWithoutFeedback)`
  display: flex;
  flex: 1;
`;

export const StyledActivityIndicatorWrapper = styled.View<{
  dimensions: ScaledSize;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
`;

export const StyledPhotoFullImage = styled(ImageBackground)<{
  dimensions: ScaledSize;
}>`
  width: ${({ dimensions }) => dimensions.width}px;
  height: ${({ dimensions }) => dimensions.height}px;
  margin: 0;
`;
